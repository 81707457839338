.jumbotron {
  position: relative;
  overflow: hidden;
  background-color:black;
}
.jumbotron video {
  position: absolute;
  z-index: 1;
  top: 0;
  width:100%;
  height:100%;
  /*  object-fit is not supported on IE  */
  object-fit: cover;
  opacity:0.5;
}
.jumbotron .container {
  z-index: 2;
  position: relative;
}


/* Intro Header */
.mainHeader h1{
  font-weight:700;
}

/* Sub Header */
#hero h1.display-4{
  font-weight: 400;
  
  text-shadow: 1px 3px 11px rgba(0, 0, 0, 1);
}
#hero.withImage{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.subHeader .card{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
/* Gradient to read text on cards */
.subHeader .card div{
  background: linear-gradient(to top, rgba(0, 0, 0, 0.66), transparent);
}
.subHeader .card > a{
  text-decoration:none;
}

/*Single Page*/
/* Video */
.video-container {
   position: relative;
   padding-bottom: 56.25%;
   height: 0;
   overflow: hidden;
}

.video-container img{
   width:100%;
}

.video-container iframe,
.video-container object,
.video-container embed,
.video-container video {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}
