$theme-colors: (
  "primary": #8600a7,
  "danger": #FF0000
);

$link-color:#8600a7;

@import "~bootstrap/scss/bootstrap";
@import "./sass/custom";


html {
    scroll-behavior: smooth;
}